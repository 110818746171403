@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';

// There are some major variables below,
// all less variables could be found in
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

// font
@font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

// base variables
@border-radius-base: 6px;

// colors
@primary-color: #466040;
@error-color: #fa541c;
@text-color: #626262;

// sider
@layout-sider-background: #186f5a;
@menu-bg: transparent;
@menu-item-color: #fff;
@menu-highlight-color: #fff;
@menu-item-active-bg: transparent;
@menu-item-active-border-width: 0;
@layout-trigger-background: rgba(0, 0, 0, 0.5);

//form
@label-color: @text-color;

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0;
}

.ant-form-item-label > label {
  font-weight: bold;
}

.ant-page-header {
  border-radius: @border-radius-base;
}

/* -------------------------------------------*\
    Form - Ant design CSS
\*------------------------------------------- */
.ant-input-group-addon .ant-form-item {
  margin-bottom: 0;
}

.ant-input-group-addon .ant-form-item .ant-form-item-control-input {
  min-height: auto;
}

.ant-form-item-explain-error {
  font-size: 12px;
}

.ant-checkbox-disabled + span {
  color: rgb(0 0 0 / 35%);
}

.ql-editor {
  min-height: 200px;
}


.ant-btn-primary:not(:disabled) {
  background-color: @primary-color !important;
}

.ant-switch {
  background-color: #BFBFBF !important;
}

.ant-switch-checked {
  background-color: @primary-color !important;
}