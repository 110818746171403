.titleHeader {
  font-size: 1.5rem;
  font-weight: 500;
}

.splitLine {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgb(0 0 0 / 10%);
}

.status {
  flex-direction: row !important;
  align-items: baseline;

  label {
    margin-right: 50px  !important;
  }
}
