@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Libre Franklin', Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    display: block;
    width: 100%;
  }

  div {
    display: block;
  }
}
