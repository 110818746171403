.titleFilter {
  cursor: default;
  padding-right: 8px;
  min-width: 180px;
}

.formFilterItem {
  margin: 8px 0 20px;
}

.formNumberInput {
  width: 100%;
}
